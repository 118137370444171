import { createAction, props } from '@ngrx/store';
import { ConfigurationCreateInput, ConfigurationValue, IConfiguration } from '@wap/configuration';
import { CourseConfiguration, UserConfiguration } from '@app/configuration/configuration.model';

export const load = createAction('[Application Initializer] Load Configurations');

export const loadSuccess = createAction(
  '[Configuration API] Load Configurations Success',
  props<{ configuration: IConfiguration }>(),
);

export const loadFail = createAction(
  '[Configuration API] Load Configurations Fail',
  props<{ error: string }>(),
);

export const loadCourseConfigurationsSuccess = createAction(
  '[Configuration API] Load Course Configurations Success',
  props<{ courseConfiguration: Partial<CourseConfiguration> }>(),
);

export const createOrUpdate = createAction(
  '[Configuration API] Create Configuration',
  props<Partial<ConfigurationCreateInput>>(),
);

export const createSuccess = createAction(
  '[Configuration API] Create Configuration Success',
  props<{ configuration: IConfiguration }>(),
);

export const createFail = createAction(
  '[Configuration API] Create Configuration Fail',
  props<{ error: string }>(),
);

export const saveUserConfiguration = createAction(
  '[Configuration API] Save user Configuration',
  props<{ key: keyof UserConfiguration; value: ConfigurationValue }>(),
);

export const saveUserConfigurationSuccess = createAction(
  '[Configuration API] Save user Configuration Success',
  props<{ configuration: IConfiguration }>(),
);

export const saveUserConfigurationFail = createAction(
  '[Configuration API] Save user Configuration Fail',
  props<{ error: string }>(),
);
